import { Injectable } from '@angular/core';
import { API_ROUTES } from '@constants/api-routes';
import {
  ObtainTokenPostRequest,
  ObtainTokenPostResponse,
  RefreshToken,
  RefreshTokenPostResponse,
} from '@models/auth.model';
import { RefreshSubjectEntity } from '@services/data-access/_core/trigger-entities.service';
import { Observable } from 'rxjs';
import { CoreApiService } from '../_core/core-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends CoreApiService {
  protected entityName: RefreshSubjectEntity = RefreshSubjectEntity.Auth;

  postRefreshToken(
    refresh: RefreshToken['refresh'],
  ): Observable<RefreshTokenPostResponse> {
    return this.http.post<RefreshTokenPostResponse>(API_ROUTES.token.refresh, {
      refresh,
    });
  }

  postObtainToken(
    body: ObtainTokenPostRequest,
  ): Observable<ObtainTokenPostResponse> {
    return this.http.post<ObtainTokenPostResponse>(API_ROUTES.token.pair, body);
  }
}
