import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthLogin, RefreshTokenPostResponse } from '@models/auth.model';
import { Nullable } from '@models/nullable.model';
import { AuthApiService } from '@services/data-access/entities/auth-api.service';
import { AuthStateService } from '@state-management/auth-state';
import { SystemStateService } from '@state-management/system-state';
import { parseJWT } from '@utils/jwt-manipulation';
import {
  catchError,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { OfflineIndexedDbService } from './indexed-db/offline/offline-indexed-db.service';
import { MeService } from './me.service';
import { ServiceWorkerService } from './service-worker.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly stateService = inject(StateService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly serviceWorkerService = inject(ServiceWorkerService);
  private readonly router = inject(Router);
  private readonly authApiService = inject(AuthApiService);
  private readonly authStateService = inject(AuthStateService);
  private readonly meService = inject(MeService);
  private readonly offlineIndexedDbService = inject(OfflineIndexedDbService);

  login(data: AuthLogin): Observable<RefreshTokenPostResponse> {
    return this.authApiService.postObtainToken(data).pipe(
      tap(({ access, refresh }) => {
        this.authStateService.setMultipleValues({
          access,
          refresh,
        });
      }),
      tap(({ refresh }) => {
        this.setExpirationDateFromToken(refresh);
      }),
      switchMap((result) => {
        return this.meService.initMeStates().pipe(
          catchError(() => of(null)),
          map(() => result),
        );
      }),
    );
  }

  logout(): void {
    const isUpdateAvailable =
      this.systemStateService.getValue('isUpdateAvailable')();
    this.stateService.flushStates();
    localStorage.clear();
    this.router.navigate(['']);
    const deleteDB$ = this.offlineIndexedDbService.deleteAllData().pipe(
      take(1),
      catchError(() => of(null)),
    );
    const clearCache$ = this.serviceWorkerService.clearCache().pipe(
      take(1),
      catchError(() => of(null)),
    );
    forkJoin([deleteDB$, clearCache$])
      .pipe(
        take(1),
        finalize(() => isUpdateAvailable && window.location.reload()),
      )
      .subscribe();
  }

  refreshToken(refreshToken: string): Observable<RefreshTokenPostResponse> {
    return this.authApiService.postRefreshToken(refreshToken).pipe(
      tap(({ access, refresh }) => {
        this.authStateService.setMultipleValues({
          access,
          refresh,
        });
      }),
      tap(({ refresh }) => this.setExpirationDateFromToken(refresh)),
    );
  }

  setExpirationDateFromToken(token: Nullable<string>): void {
    if (!token) {
      this.authStateService.resetValue('tokenExpiredAt');
      return;
    }

    const result = parseJWT(token);
    if (!result) return;

    this.authStateService.setValue('tokenExpiredAt', result.exp);
  }
}
