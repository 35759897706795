import { Injectable, effect, untracked } from '@angular/core';
import { CoreStateService } from '../_core/core-state.service';
import { initialState } from './auth-state.config';
import {
  AuthStateReadonlyInterface,
  AuthStateWritableInterface,
} from './auth-state.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService extends CoreStateService<
  AuthStateWritableInterface,
  AuthStateReadonlyInterface
> {
  constructor() {
    super(initialState);
    this.isLoggedEffect();
  }

  private isLoggedEffect(): void {
    effect(() => {
      const tokenExpiredAt = this.getValue('tokenExpiredAt')();
      untracked(() => this._setReadonlyValue('isLogged', !!tokenExpiredAt));
    });
  }
}
